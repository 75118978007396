/* purgecss start ignore */
/* add smaller margin top to the first component inside this class */
.components {
    div:first-child {
        .component {
            @apply mt-45; 
        }
    }
}

.inline-p {
    p {
        display: inline;
    }
}
/* purgecss end ignore */