/* purgecss start ignore */
.footer {
	p {
		color: #fff;
	}
}
.text-content hr {
    @apply my-32;
}

.inline-symbol {
	display: inline;
	margin: 0 !important;
}
/* purgecss end ignore */