.product-link-list [data-toggle-trigger] {
    .product-link-list__item-icon {
        @apply block h-12 w-12 ml-auto relative;

        &:before,
        &:after {
            @apply absolute bg-black transition-transform duration-500;
            content: '';
            height: 3px;
            left: 50%;
            margin: -1px 0 0 -10px;
            top: 50%;
            width: 20px;
        }

        &:after {
            transform-origin: center;
            transform: rotate(90deg);
        }

        &:before {
            transform: rotate(180deg);
        }
    }

    &.is-active {

        h3 {
            @apply font-eina-semibold;
        }

        .product-link-list__item-icon {
            &:after {
                @apply duration-500;
                transform: rotate(0deg);
            }

            &:before {
                @apply duration-500;
                transform: rotate(0deg);
            }
        }
    }
}

.product-link-list [data-toggle-target] {
    @apply duration-500 max-h-0 overflow-hidden;

    &.is-active {
        @apply duration-1000;
        max-height: 1000rem;
    }
}