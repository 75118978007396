/* purgecss start ignore */

.EPiServerForms:not(.form-page__form .EPiServerForms):not(.text-content .EPiServerForms) {
	@apply max-w-screen-xl mx-auto px-15;
}

.elq-form .container-fluid:after, .elq-form .container-fluid:before, .elq-form .row:after, .elq-form .row:before {
	display: none !important;
	content: none !important;
}

.elq-form .row {
	clear: both;
}

form, .elq-form {
	h2 {
		@apply font-eina-semibold mb-30 mt-50 text-h2-mobile md:text-h2;
	}

	/* fieldset */
	fieldset {
		@apply m-0 p-0;
	}

	/* legend */
	legend {
		@apply block mb-10 text-lg font-black relative w-full;
	}

	.elq-field-style:has(.single-checkbox-row) {
		@apply my-10 !important;
	}


	.form-element-layout {
		padding-top: 0;
		padding-bottom: 0;
	}

	/* labels */
	.Form__Element, .elq-field-style {
		@apply my-25 !important;

		/* Do not apply styles using !important when we are styling with tailwind */
		label:not(.tailwind-label) {
			@apply block mb-10 text-lg text-primary font-eina-semibold relative !important;

			.elq-required {
				@apply text-primary !important;
			}
		}
	}

	/* input text and textarea */
	.FormCaptcha,
	.FormTextbox,
	.Form__CustomElement, .field-control-wrapper {
		input,
		textarea,
		.FormCaptcha input {
			@apply block border border-black p-10 w-full;
		}
	}

	.FormTextbox {
		textarea {
			@apply h-150;
		}
	}

	/* file-upload */
	.FormFileUpload {
		label {
			@apply block w-full;
		}

		input {
			@apply block py-10 w-full;

			&::file-selector-button {
				@apply bg-white border-0 font-eina-semibold inline-block mr-15 text-base px-20 py-15 rounded-full text-center text-orionBlue transition-all;
				box-shadow: inset 0 0 0 2px theme('colors.orionBlue');

				&:hover {
					@apply bg-orionDarkBlue text-white;
				}
			}
		}
	}

	/* checkbox and radio */
	.FormChoice fieldset > div, .row .single-checkbox-row {
		@apply flex items-start mb-15;

		label {
			@apply mb-0 ml-15 !font-eina;
		}

		/* checkbox */
		input[type="checkbox"] {
			@apply appearance-none border border-black cursor-pointer mt-5 relative h-20 w-20 shrink-0;

			&::before {
				@apply absolute block h-14 opacity-0 top-0 w-8;
				content: '';
				left: 5px;
				border-style: solid;
				border-color: white;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
			}

			&:checked {
				@apply bg-orionBlue border-orionBlue text-white;

				&::before {
					opacity: 1;
				}

				~ label::before {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
				}
			}
		}

		/* radio */
		input[type="radio"] {
			@apply appearance-none border border-black cursor-pointer mt-5 relative rounded-full h-20 w-20;

			&::before {
				@apply absolute block h-14 opacity-0 top-0 w-14;
			}

			&:checked {
				@apply bg-orionBlue border-orionBlue text-white;

				&::before {
					opacity: 1;
				}

				~ label::before {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
				}
			}
		}
	}

	/* select */
	select, select.elq-item-select {
		@apply appearance-none block border border-black py-10 px-20 rounded-full w-full;
		background: right 20px center no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 14L0.205774 0.499999L15.7942 0.5L8 14Z' fill='%23010C1D'/%3e%3c/svg%3e ");
	}

	/* buttons */
	button.Form__Element {
		@apply mr-25 !important;
	}

	/* submit button */
	.FormSubmitButton, input[type=submit].submit-button-style {
		@apply bg-orionBlue font-eina-semibold inline-block text-base px-20 py-15 rounded-full text-center text-white transition-all border-none;
		will-change: transform;

		&:hover {
			@apply bg-orionDarkBlue;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
			transform: translate3d(0, -2px, 0);
		}

		&:focus-visible {
			@apply bg-orionDarkBlue shadow-none text-white;
		}
	}

	.FormResetButton {
		@apply bg-white font-eina-semibold inline-block text-base px-20 py-15 rounded-full text-center text-orionBlue transition-all;
		box-shadow: inset 0 0 0 2px theme('colors.orionBlue');
		will-change: transform;

		&:hover {
			@apply text-orionMiddleBlue;
			box-shadow: inset 0 0 0 2px theme('colors.orionBlue'), rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
			transform: translate3d(0, -2px, 0);
		}

		&:focus-visible {
			@apply bg-orionDarkBlue shadow-none text-white;
		}
	}

	.epiform-tooltip-icon {
		@apply inline-block;

		@media (min-width: theme('screens.lg')) {
			@apply relative;
		}

		&::before {
			@apply align-middle text-xs inline-flex items-center justify-center ml-10 relative rounded-full text-black -top-2 h-20 w-20;
			background: center no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9.00033 0.666992C4.40033 0.666992 0.666992 4.40033 0.666992 9.00033C0.666992 13.6003 4.40033 17.3337 9.00033 17.3337C13.6003 17.3337 17.3337 13.6003 17.3337 9.00033C17.3337 4.40033 13.6003 0.666992 9.00033 0.666992ZM9.83366 13.167H8.16699V8.16699H9.83366V13.167ZM9.83366 6.50033H8.16699V4.83366H9.83366V6.50033Z' fill='currentColor'/%3e%3c/svg%3e");
			content: "";
		}

		&:hover {
			.epiform-tooltip {
				@apply inline-block;
			}
		}
	}

	.epiform-tooltip {
		@apply absolute bg-white bottom-30 hidden left-0 p-30 shadow-xl m-auto w-full z-50;

		@media (min-width: theme('screens.lg')) {
			@apply bottom-20 left-40 w-max max-w-2xl;
		}

		&:hover {
			display: none !important;
		}
	}
}

.form-page__form {
	.EPiServerForms .Form__Status {
		.Form__Status__Message {
			@apply p-0;
		}

		.Form__Warning__Message {
			@apply bg-orionBlue text-white;
		}

		.Form__Success__Message {
			@apply bg-white text-primary;
		}
	}
}


.FormRegisteration {
	.epiform-tooltip-icon {
		&::before {
			@apply ml-0;
		}
	}
}

/* purgecss end ignore */