/* purgecss start ignore */

/* base button style */
.button, .button[type='submit'] {
    @apply font-eina-semibold inline-block text-base px-20 py-15 rounded-full text-center transition-all;
    will-change: transform;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        transform: translate3d(0, -2px, 0);
    }

    &:focus-visible {
        @apply bg-orionDarkBlue shadow-none text-white;
    }

    &.translate3d-0 {
        transform: translate3d(0, 0, 0);
    }

    &.button-small {
        @apply px-20 py-10 text-md;
    }
}

/* default blue */
.button--default-blue, .button--default-blue[type='submit'] {
    @apply bg-orionBlue text-white;

    &:hover {
        @apply bg-orionDarkBlue;
    }
}

/* default green */
.button--default-green, .button--default-green[type='submit'] {
    @apply bg-green text-white;

    &:hover {
        @apply bg-green;
    }
}

/* default red */
.button--default-red, .button--default-red[type='submit'] {
    @apply bg-darkRed text-white;

    &:hover {
        @apply bg-darkRed;
    }
}

/* default white */
.button--default-white {
    @apply bg-white text-black;

    &:hover {
        @apply bg-orionDarkBlue text-white;
    }
}

/* default light blue */
.button--default-light-blue, .button--default-light-blue[type='submit'] {
    @apply bg-orionBlue10 text-orionBlue;

    &:hover {
        @apply bg-orionBlue text-white;
    }
}

/* outline blue */
.button--outline-blue, .button--outline-blue[type='submit'] {
    @apply bg-white text-orionBlue;
    box-shadow: inset 0 0 0 2px theme('colors.orionBlue');

    &:hover {
        @apply text-orionMiddleBlue;
        box-shadow: inset 0 0 0 2px theme('colors.orionBlue'),rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }

    &.lock-hover {
        @apply text-orionMiddleBlue;
        box-shadow: inset 0 0 0 2px theme('colors.orionBlue'),rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
}

/* outline black */
.button--outline-black {
    @apply bg-white text-black;
    box-shadow: inset 0 0 0 2px #000;

    &:hover {
        box-shadow: inset 0 0 0 2px #000,rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
}

/* arrow link */
.arrow-link {
    @apply inline-flex font-eina-semibold items-center text-orionBlue;

    .material-icons {
        @apply ml-5 relative text-[150%] top-[2px] transition-transform;
    }

    &:hover {
        @apply text-orionMiddleBlue;
        .material-icons {
            @apply translate-x-5;
        }
    }
}

.play {
    &:after {
        content: "";
        display: block;
        height: 7rem;
        width: 7rem;
        background-size: 7rem 7rem;
        background-image: url(/Static/img/icon-play.svg);
        background-repeat: no-repeat;
        transition: .1s ease-in-out;
    }

    &:hover {
        &:after {
            height: 9rem;
            width: 9rem;
            background-size: 9rem 9rem;
            margin-top: -1rem;
        }
    }
}

.stop {
    &:after {
        content: "";
        width: 5rem;
        height: 5rem;
        content: "";
        display: block;
        height: 7rem;
        width: 7rem;
        background-size: 7rem 7rem;
        margin: 1rem;
        background-image: url(/Static/img/icon-pause.svg);
        background-repeat: no-repeat;
        transition: .1s ease-in-out;
    }

    &:hover {
        &:after {
            height: 9rem;
            width: 9rem;
            background-size: 9rem 9rem;
            margin: 0;
        }
    }
}
/* purgecss end ignore */