/* Used in Optimizely dropdown and icon-links-block */
.icon {
    display: inline-block;
    height: 24px;
    width: 24px;

    &::after {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
    }
}

/* Used in Optimizely to have blue bg when white icons */
.dijitReset {
    .icon {
        height: 24px!important;
        padding: 5px;
        width: 24px!important;
    }

    .icon-orion-icon-newsroom,
    .icon-orion-icon-service-desk,
    .icon-orion-icon-career {
        background-color: #00509B;
    }
}

/* icon links */
.icon-orion-icon-science-and-partnering::after {
    background-image: url(/Static/icons/sience-and-partnering.svg);
}

.icon-orion-icon-sustainability::after {
    background-image: url(/Static/icons/sustainability.svg);
}

.icon-orion-icon-we-are-orion::after {
    background-image: url(/Static/icons/we-are-orion.svg);
}

.icon-orion-icon-orion-and-society::after {
    background-image: url(/Static/icons/orion-and-society.svg);
}

.icon-orion-icon-your-health::after {
    background-image: url(/Static/icons/your-health.svg);
}

.icon-orion-icon-fee::after {
    background-image: url(/Static/icons/fee.svg);
}

.icon-orion-icon-hedging::after {
    background-image: url(/Static/icons/hedging.svg);
}

.icon-orion-icon-work-life-balance::after {
    background-image: url(/Static/icons/work-life-balance.svg);
}

.icon-orion-icon-company-health-management::after {
    background-image: url(/Static/icons/company-health-management.svg);
}

.icon-orion-icon-mobility::after {
    background-image: url(/Static/icons/mobility.svg);
}

.icon-orion-icon-human-resources::after {
    background-image: url(/Static/icons/human-resources.svg);
}

.icon-orion-icon-safety::after {
    background-image: url(/Static/icons/safety.svg);
}

.icon-orion-icon-events::after {
    background-image: url(/Static/icons/events.svg);
}

.icon-orion-icon-more-puzzle::after {
    background-image: url(/Static/icons/more-puzzle.svg);
}

/* search quicklinks */
.icon-orion-icon-newsroom::after {
    background-image: url(/Static/icons/newsroom.svg);
}

.icon-orion-icon-service-desk::after {
    background-image: url(/Static/icons/service-desk.svg);
}

.icon-orion-icon-career::after {
    background-image: url(/Static/icons/career.svg);
}

.Sleek {
    .epi-advancedListing li h3 {
        font-size: 16px;
        margin: 5px 0 5px 0;
        font-weight: 500;
    }

    .epi-advancedListing li p {
        font-size: 13px;
    }
}

div[data-dojo-attach-point="allowedTypesWrapper"], div[data-dojo-attach-point="restrictedTypesWrapper"] {
    strong {
        font-size: 10px;
    }

    p {
        font-size: 10px;
        font-weight: 600;
    }
}

.Sleek .epi-content-area-wrapper .epi-content-area-header-block {
    margin-bottom: -2px !important;
    border-bottom: 0 !important;
    padding: 2px 7px !important;
}

.Sleek .epi-tree-mngr .epi-tree-mngr--block .dijitTreeContent {
    background-color: #E5ECF3;
}

.Sleek .epiTinyMCEEditor.dijitContainer {
    width: 100%;
    max-width: 980px;

    .epiTinyMCEEditor {
        width: 100%;

        .tox-tinymce {
            width: 100% !important;
        }
    }
}

.dijitTabPaneWrapper .epi-form-container__section__label-container label {
    font-size: 16px;
    font-weight: 600;
}

.Sleek .epi-form-container__section__row > fieldset {
    legend {
        font-weight: 600;
    }

    > .epi-form-container__section {
        border: 1px solid hsl(0,0%,62%);
        border-color: hsl(0,0%,84%);
        border-radius: 4px;
        max-width: 983px;
        padding-right: 4px;
    }
}

.Sleek .epi-form-container__section__row > fieldset .epi-form-container__section__row--field {
    margin: 0 !important;
}
.Sleek .epi-form-container__section__row > fieldset > .epi-form-container__section .epi-form-container__section {
    border: 0;
}