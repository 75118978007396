/* purgecss start ignore */

/* Add styles to text-content elements from Optimizely "Rich text editor" with exception of elements in blocks inside the "Rich text editor" */

.text-content {
    @apply mb-90 text-base;

    .component {
        .container {
            padding: 0;
        }
    }

    .component:not(.accordion):not(.iframe):not(.process):not(.highlight):not(.quote):not(.advanced-highlight) {
        .container {

            @media (min-width: theme('screens.md')) {
                width: 100vw;
                position: relative;
                left: calc(-1 * (100vw - 100%) / 2);
            }

            @media (min-width: theme('screens.xl')) {
                width: theme('screens.xl');
                position: relative;
                left: calc(-1 * (theme('screens.xl') - 100%) / 2);
            }
        }
    }

    .component.quote {
        .container {
            @media (min-width: theme('screens.xl')) {
                width: theme('screens.xl');
                position: relative;
                left: calc(-1 * (theme('screens.xl') - 100%) / 2);
            }
        }
    }

    .component.advanced-highlight {
        margin-top: 5rem;
        padding-top: 0;
        margin-bottom: 5rem;
        padding-bottom: 0;

        &:first-of-type {
            border-top: 1px solid theme(colors.greyLight);
            margin-top: 5rem;
            padding-top: 5rem;
        }

        &:last-of-type {
            border-bottom: 1px solid theme(colors.greyLight);
            margin-bottom: 5rem;
            padding-bottom: 5rem;
        }
    }
}

.text-content-white {
    a {
        @apply text-white;
        color: #fff !important;
    }
}

.text-content h1:not(.text-content > .component h1),
.text-content .text-content h1,
.FormParagraphText h1,
.h1 {
    @apply font-eina-semibold mb-30 text-h1-mobile md:text-h1;
}

.text-content h2:not(.text-content > .component h2),
.text-content .text-content h2,
.FormParagraphText h2,
.h2 {
    @apply font-eina-semibold mb-18 mt-50 text-h2-mobile md:text-h2;
}

.text-content h3:not(.text-content > .component h3),
.text-content .text-content h3,
.FormParagraphText h3,
.h3 {
    @apply font-eina-semibold mb-18 mt-50 text-h3;
}

.text-content h4:not(.text-content > .component h4),
.text-content .text-content h4,
.FormParagraphText h4,
.h4 {
    @apply font-eina-semibold mb-18 mt-50 text-h4;
}

.text-content a:not(.text-content > .component a),
.text-content .text-content a,
.FormParagraphText a,
.link {
    @apply text-orionBlue underline;

    &:hover,
    &focus-visible {
        @apply text-black;
    }
}

.text-content p:not(.text-content > .component p),
.text-content .text-content p,
.FormParagraphText p {
    @apply my-18;
}

.text-content ul {
    @apply leading-relaxed list-disc ml-15 my-30 pl-10;
}

.text-content ol {
    @apply leading-relaxed list-decimal ml-15 my-30 pl-20;
}

.text-content img:not(.text-content > .component img) {
    @apply rounded-xl mb-80 mt-30;
}

/* Figcaption style */
.text-content {
    figure.image {
        figcaption {
            @apply mb-70 -mt-40 text-md text-primaryLight;
        }
    }
}

.text-content {
    table {
        @apply border-collapse w-full;

        td:not(td:first-child) {
            @apply text-right;
        }

        &.table--left-center {
            td:first-child {
                @apply text-left;
            }

            td:not(td:first-child) {
                @apply text-center;
            }
        }

        &.table--left {
            td:not(td:first-child) {
                @apply text-left;
            }
        }
    }

    th, td {
        @apply align-top p-15;
        border-bottom: 1px solid theme('colors.gray.300');
        height: auto !important;
        min-width: 10rem;
        width: auto !important;

        &:first-child {
            @apply pl-0;
        }

        &:last-child {
            @apply pr-0;
        }
    }

    thead td, th {
        @apply font-eina-semibold text-left;
    }
}

/* arrow link for text-content */
.text-content {
    .arrow-link {
        @apply no-underline !important;

        &::after {
            @apply ml-5 material-icons transition-transform translate-x-0;
            content: "\E8E4";
            font-size: 32px;
        }

        &:hover {
            &::after {
                @apply translate-x-4;
            }
        }
    }
}

/* text-content modifier for components */
.text-content--component {
    @apply my-0;

    > *:first-child {
        @apply mt-0 !important;
    }

    > *:last-child {
        @apply mb-0 !important;
    }
}

/* text-content modifier for process block */
.text-content--process {
    h3,
    h4,
    h5 {
        + ul,
        + p {
            @apply -mt-20 !important;
        }
    }
}

/* text-content modifier for release pages */
.text-content--release {
    img {
        @apply m-0 w-auto !important;
    }
}

/* purgecss end ignore */