/* Add gap between tabs */
.MuiTabs-scroller > div {
    @media screen and (min-width: theme('screens.md')) {
        gap: 30px;
    }

    @media screen and (min-width: theme('screens.lg')) {
        gap: 60px;
    }
}

/* Set size of scroll icons */
.MuiTabScrollButton-horizontal {
    svg {
        fill: theme('colors.orionBlue');
        height: 4rem;
        width: 4rem;
    }
}

/* Set selected color and remove click effect */
.MuiButtonBase-root {

    &.Mui-selected {
        color: theme('colors.orionBlue')!important;
    }

    span {
        * {
            display: none;
        }
    }
}