@import './../../Static/fontawesome/all.min.css';



/* Temporary typography setup */

/* purgecss start ignore */
@font-face {
    font-family: 'eina03-semibold';
    src: url('/Static/webfonts/Eina03-SemiBold.otf') format('opentype');
}

@font-face { /* Replace "> < π" Characters with arial due to eina not supporting them */
	font-family: 'eina03-semibold';
	src: local("Arial"), local("Helvetica");
	unicode-range: U+2265, U+2264, U+03C0;
}

@font-face {
	font-family: 'eina01-light';
	src: url('/Static/webfonts/Eina01-Light.otf') format('opentype');
}

@font-face { /* Replace "> < π" Characters with arial due to eina not supporting them */
	font-family: 'eina01-light';
	src: local("Arial"), local("Helvetica");
	unicode-range: U+2265, U+2264, U+03C0;
}

@font-face {
	font-family: 'eina01-regular';
	src: url('/Static/webfonts/Eina01-Regular.otf') format('opentype');
}

@font-face { /* Replace "> < π" Characters with arial due to eina not supporting them */
	font-family: 'eina01-regular';
	src: local("Arial"), local("Helvetica");
	unicode-range: U+2265, U+2264, U+03C0;
}

html {
	font-size: 62.5%;
}

body {
	@apply font-eina text-base text-primary;
	-webkit-font-smoothing: antialiased;
}

.ingress {
	font-family: 'eina01-light';
	font-style: normal;
	font-size: 28px;
	line-height: 32px;
	margin-top: 1rem;
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('/Static/webfonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
	src: local('Material Icons'), local('MaterialIcons-Regular'),
	url('/Static/webfonts/MaterialIcons-Regular.woff2') format('woff2'),
	url('/Static/webfonts/MaterialIcons-Regular.woff') format('woff'),
	url('/Static/webfonts/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: 'liga';
}
/* purgecss end ignore */