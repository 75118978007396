@import 'typography.scss';
@import 'global-elements.scss';
@import 'navigation.scss';
@import 'language-menu.scss';
@import 'buttons.scss';
@import 'text-content.scss';
@import 'page-header-search.scss';
@import 'site-search.scss';
@import 'edit.scss';
@import 'external-elements.scss';

@import 'forms/form-elements.scss';

@import 'components/accordion.scss';
@import 'components/quote.scss';
@import 'components/tabs.scss';
@import 'components/process.scss';
@import 'components/product-link-list.scss';

@import 'utilities.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
body {
	@apply m-0 p-0;
}

#epi-quickNavigator {
	opacity: .25;
	transition: all .5s;
	transform: translateY(-75%);

	&:hover {
		opacity: 1;
		transform: translateY(0%);
	}
}

#epi-quickNavigator-logo {
	display: none;
}

/* temp solution due to arbitary class not working (Used on article page main image) */
.article__main-img {
	@apply -mx-15 max-w-none rounded-xl;
	width: calc(100% + 3rem);

	@media (min-width: theme('screens.xl')) {
		@apply -mx-100;
		width: calc(100% + 20rem);
	}
}

/* purgecss end ignore */

